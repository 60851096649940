import {z} from "zod";
import {GetAppRootUrl} from "./MsixAppRootClient";
import {GetAuthorization, GetRoles} from "../Auth/MsalTokenClient";
import {GetMsalConfig} from "../Auth/MsalClientInit";

function parseDate(dateString: string) {
    //.NET returns 7 milliseconds, js supports 3
    const utc = dateString.endsWith('Z');
    dateString = dateString.substring(0, "yyyy-MM-ddTHH:mm:ss.fff".length);
    if (utc)
        dateString += 'Z'; //Add back the Z, if it was there
    return new Date(dateString);
}

const msixAppInfoSchema = z.object({
    appId: z.string(),
    appName: z.string(),
    appDescription: z.string().nullish(),
    publisherName: z.string().nullish(),
    version: z.string(),
    sourceRevisionId: z.string().nullish(),
    buildDateUtc: z.string().transform(parseDate),
    svgLogo: z.string().nullish()
});

const appFileSchema = z.object({
    name: z.string(),
    size: z.number(),
    eTag: z.string(),
});

const appVersionInfoSchema = z.object({
    version: z.string(),
    buildDate: z.string().transform(parseDate),
    tags: z.record(z.string(),z.string()),
    files: appFileSchema.array()
});

export type MsixAppInfo = z.infer<typeof msixAppInfoSchema>;
export type AppVersionInfo = z.infer<typeof appVersionInfoSchema>;

export interface MsixApplication {
    info: MsixAppInfo;
    version: AppVersionInfo;
}

const accessDeniedSchema = z.object({
    //Current user roles
    roles: z.string().array().nullable(),
    accessDenied: z.literal(true)
})

export type AccessDenied = z.infer<typeof accessDeniedSchema>;

//Gets a blob download URL for a file in the app
export async function GetMsixFileBlobUrl(relativePath: string) {
    
    const url = `${await GetAppRootUrl()}/Files/${relativePath}`;
    const response = await fetch(url, {
        headers: {
            Authorization: await GetAuthorization()
        }
    })
    if (!response.ok) {
        throw new Error(`Failed to get file download URL. ${response.status} ${response.statusText}`);
    }
    return await response.text();
}

async function GetMsixAppVersionInfo(version: string) : Promise<AppVersionInfo> {
    const requestInit : RequestInit = {
        headers: {
            Authorization: await GetAuthorization(),
            Accept: 'application/json'
        }
    };
    const response = await fetch(`${await GetAppRootUrl()}/Versions/${version}`, requestInit);
    if (!response.ok) {
        throw new Error(`Failed to fetch version ${version}. ${response.status} ${response.statusText}`);
    }
    //Might be null, but MSAL should fail before then
    return appVersionInfoSchema.parse(await response.json());
}

async function GetMsixAppInfo() : Promise<MsixAppInfo> {
    //Configuration is hosted at /apps/{appId}/Info
    const requestInit : RequestInit = {
        headers: {
            Authorization: await GetAuthorization()
        }
    };
    let response = await fetch(`${await GetAppRootUrl()}/Info`, requestInit);
    if (!response.ok) {
        throw new Error(`Failed to fetch app info. ${response.status} ${response.statusText}`);
    }
    const info = msixAppInfoSchema.parse(await response.json());
    if (info.svgLogo)
    {
        //Svg logo contains a filepath, get BLOB url
        info.svgLogo = await GetMsixFileBlobUrl(info.svgLogo);
    }
    return info;
}

export async function GetMsixApplication() : Promise<MsixApplication | AccessDenied> {
    const msalConfig = await GetMsalConfig();

    const roles = msalConfig.roles;
    if (roles && roles?.length > 0) {
        const roles = await GetRoles();
        //Check user has at least one of the required roles
        if (!roles.some(r => roles.includes(r))) {
            //Access denied
            return { 
                roles,
                accessDenied: true
            };
        }
    }
    
    //Get the latest version and config
    
    const info = await GetMsixAppInfo();
    const version = await GetMsixAppVersionInfo(info.version);
    
    return { info, version };
}