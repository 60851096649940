import AsyncComponent from "../AsyncComponent";
import {AccessDenied, AppVersionInfo, GetMsixApplication, GetMsixFileBlobUrl, MsixApplication} from "./MsixAppClient";
import "./MsixApp.css";

async function DownloadFile(version: AppVersionInfo, extension: string, target: EventTarget) {
    const file = version.files.find(f => f.name.endsWith(extension));
    if (!file) {
        throw new Error(`No file with extension ${extension} found`);
    }
    console.log(`Downloading ${file.name}`);
    const button = target as HTMLButtonElement;
    button.disabled = true;
    try {
        const blobUrl = await GetMsixFileBlobUrl(file.name);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = file.name;
        a.click();
    }
    finally {
        button.disabled = false;
    }

}

function RenderAccessDenied() {
    return (
        <div style={{color: 'red'}}>
            <h1>Access denied</h1>
            <p>You do not have permission to access this application.</p>
        </div>
    )
}

function RenderDownload(app: MsixApplication) {
    const {info,version} = app;
    
    return (
        <div className="row">
            <div className="col-5">
                <h1 style={{color: "#0078D4"}}>{info.appName}</h1>
                <p style={{fontSize: '20px'}}>Version {version.version}</p>
                {info.appDescription ? <p>{info.appDescription}</p> : null}

                <button className="install" onClick={e => DownloadFile(version, '.appinstaller', e.target)}>Install
                </button>
                <br/>
                <div>
                    <p>Additional files</p>
                    <ul style={{listStyleType: 'none', fontSize: '0.9em'}}>
                        <li>
                            <button className="link"
                                    onClick={e => DownloadFile(version, '.msixbundle', e.target)}>Download Bundle
                            </button>
                        </li>
                        <li>
                            <button className="link" onClick={e => DownloadFile(version, '.cer', e.target)}>Download
                                Certificate
                            </button>
                        </li>
                    </ul>
                </div>
                <p style={{fontSize: '14px'}}>Build Date: {version.buildDate.toLocaleString()}</p>
            </div>
            {info.svgLogo ? <div className="col-5"><img src={info.svgLogo } width="200px" alt="App Logo"/></div> : null}
        </div>
    );
}

export default function MsixApp() {
    return AsyncComponent({
        loader: GetMsixApplication,
        render: (data: MsixApplication | AccessDenied) => {
            if ('roles' in data) {
                return RenderAccessDenied();
            }
            document.title = `Download ${data.info.appName} - ${data.info.publisherName}`;
            return RenderDownload(data);
        }
    })
}