
import {z} from "zod";

export function GetBaseUrl()
{
    if (process.env.NODE_ENV === 'development') {
        return `http://localhost:7071`;
    } else {
        return 'https://api.apps.cloud.acu-tech.com.au';
    }
}



//UI App base url is /{appId}

export function GetAppIdFromPath()
{
    //AppId is first part of path
    const path = document.location.pathname.split('/');
    if (path.length < 2) {
        throw new Error("Invalid path, unable to extract appId");
    }
    return path[1];
}

//Gets the actual AppId from the server
export async function GetAppId()
{
    const appId = GetAppIdFromPath();
    //AppId is case-insensitive. Get the actual case from the server
    const response = await fetch(`${GetBaseUrl()}/apps/${appId}/Id`);
    if (!response.ok) {
        throw new Error(`Failed to get exact AppId. ${response.status} ${response.statusText}`);
    }
    return await response.text();
}

export async function GetAppRootUrl()
{
    return `${GetBaseUrl()}/apps/${await GetAppId()}`;
}