import React from 'react';
import './App.css';
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication} from "@azure/msal-react";
import {Authentication} from "./Auth/Authentication";
import {InteractionType} from "@azure/msal-browser";
import MsixApp from "./Msix/MsixApp";

export default function App() {
  useMsalAuthentication(InteractionType.Redirect);

  return (
      <div>
        <Authentication />

        <AuthenticatedTemplate>
          <MsixApp />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <p>You must login to continue!</p>
        </UnauthenticatedTemplate>
      </div>
  )
}

