import GetMsalClient, {GetMsalConfig} from "../Auth/MsalClientInit";
import {AuthenticationResult, InteractionRequiredAuthError} from "@azure/msal-browser";

export const AuthScheme = "AppBearer";

export async function AcquireToken() : Promise<AuthenticationResult> {
    const config = await GetMsalConfig();
    const scopes = [`api://${config.clientId}/App.Download`];

    const client = GetMsalClient();
    const account = client.getActiveAccount();
    if (!account) {
        throw new Error("No active account");
    }
    const request = {scopes, account}
    try {
        return await client.acquireTokenSilent(request);
    }
    catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
            //Doesn't return, token will be acquired silently on return
            await client.acquireTokenRedirect(request);
        }
        console.error(`Error acquiring access token\n${e}`);
        throw e;
    }
}

export async function GetAuthorization() : Promise<string> {
    const token = await AcquireToken();
    return `${AuthScheme} ${token.accessToken}`;
}

export async function GetRoles() : Promise<string[]> {
    const token = await AcquireToken();
    return ((token.idTokenClaims as any).roles as string[]) ?? [];
}