import React, {useEffect, useState} from "react";
import {PublicClientApplication} from "@azure/msal-browser";
import {InitializeMsal} from "./MsalClientInit";
import {GetAppIdFromPath} from "../Msix/MsixAppRootClient";
import {MsalProvider} from "@azure/msal-react";
import App from "../App";

function InitializeMsalComponent() {
    const [data, setData] = useState<PublicClientApplication | Error | null>(null);

    useEffect(() => {
        //We aren't at app root, try load MSAL
        const load = async () => {
            try {
                let result = await InitializeMsal();
                setData(result);
            }
            catch (e)
            {
                if (e instanceof Error)
                    setData(e);
            }
        }
        load().then();
    }, []);

    if (!data) {
        //Still loading
        return <h2>Loading authentication parameters...</h2>
    }
    if (data instanceof Error) {
        //Error initializing, most likely because no app found at path
        return (
            <div style={{ color: 'red'}}>
                <h1>Unexpected Error</h1>
                <p>Error initializing Microsoft authentication. The app '{GetAppIdFromPath()}' may not exist.</p>
                <p>{data.message}</p>
            </div>
        )
    }
    //React.StrictMode causes problems for MSAL initialization during development
    //Below here we can use it
    return (
        <React.StrictMode>
            <MsalProvider instance={data}>
                <App />
            </MsalProvider>
        </React.StrictMode>
    )
}

export default function MsixAuthHandler() {
    //Initialize MSAL and render the app

    if (document.location.pathname === "/") {
        //We are at the app root, cannot initialize MSAL
        return <h1>Please navigate to an app</h1>;
    }
    
    return <InitializeMsalComponent />;
}