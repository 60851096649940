// noinspection DuplicatedCode

import React, {useEffect, useState} from "react";

interface AsyncComponentProps<T> {
    //Data loader function
    loader: () => Promise<T>;
    //Render function
    render: (data: T) => JSX.Element;
}

//A component that loads data asynchronously
export default function AsyncComponent<T>({loader, render}: AsyncComponentProps<T>) {
    const [data, setData] = useState<T | Error | null>(null);

    useEffect(() => {
        const load = async () => {
            try {
                let result = await loader();
                setData(result);
            }
            catch (e)
            {
                console.error(e);
                if (e instanceof Error)
                    setData(e);
            }
        }
        load().then();
    }, [loader]);

    if (!data) {
        return <p>Loading...</p>
    }
    if (data instanceof Error) {
        return <p className={"text text-danger"}>Error: {data.message}</p>
    }
    return render(data);
}