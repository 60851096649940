import React from 'react';
import { useMsal } from "@azure/msal-react";
import {AccountInfo} from "@azure/msal-browser";

import Button from 'react-bootstrap/Button';

function GetName(account: AccountInfo): string {
    let name = account.name!;
    //Trim trailing '| company name'
    let index = name.lastIndexOf('|');
    if (index > 0) {
        name = name.substring(0, index);
    }
    return name.trim();
}

export function Authentication() {
    const { instance, accounts, inProgress } = useMsal();

    let wrapperStyle: React.CSSProperties = {
        display: "flex",
        justifyContent: "right",
        marginBottom: "2em"
    };
    let buttonStyle: React.CSSProperties = {
        marginLeft: "1em"
    };

    let messageStyle: React.CSSProperties = {
        alignItems: "center",
        display: "flex",
    }

    if (accounts.length > 0) {
        return (
            <div style={wrapperStyle}>
                <div style={messageStyle}><span>Hello <b>{GetName(accounts[0])}</b>!</span></div>
                <Button variant="secondary" onClick={() => instance.logout()} style={buttonStyle}>Logout</Button>
            </div>
        );
    } else if (inProgress === "login") {
        return <span>Login is currently in progress!</span>
    } else {
        return (
            <div style={wrapperStyle}>
                <div style={messageStyle}><span>Logged out!</span></div>
                <Button variant="secondary" onClick={() => instance.loginRedirect()} style={buttonStyle}>Login</Button>
            </div>
        );
    }
}