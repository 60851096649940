
import {
    AuthenticationResult,
    Configuration,
    EventMessage,
    EventType,
    PublicClientApplication
} from "@azure/msal-browser";

import 'bootstrap/dist/css/bootstrap.min.css';
import {z} from "zod";
import {GetAppId, GetAppRootUrl} from "../Msix/MsixAppRootClient"

const confSchema = z.object({
    clientId: z.string(),
    tenantId: z.string(),
    instance: z.string(),
    authority: z.string(),
    roles: z.string().array().nullish()
})

export type MsalConfiguration = z.infer<typeof confSchema>;

export async function GetMsalConfig() : Promise<MsalConfiguration> {
    const rootUrl = await GetAppRootUrl();
    let response = await fetch(`${rootUrl}/MsalConfig`);
    if (!response.ok) {
        throw new Error(`Failed to fetch get MSAL configuration. ${response.status} ${response.statusText}`);
    }
    return confSchema.parse(await response.json());
}

async function GetConfiguration() : Promise<Configuration> {
    const appId = await GetAppId();
    const src = await GetMsalConfig();
    // Redirect to the actual appId
    // This is because redirect URL is case-sensitive, 
    // and we accept a case-insensitive app ID in the URL
    const rootPath = `/${appId}`;
    return {
        auth: {
            clientId: src.clientId,
            authority: src.authority,
            redirectUri: rootPath,
            postLogoutRedirectUri: rootPath,
            navigateToLoginRequestUrl: true
        },
        cache: {
            cacheLocation: "memoryStorage",
            storeAuthStateInCookie: true
        }
    }
}

let MsalClient: PublicClientApplication | null = null;

export default function GetMsalClient() : PublicClientApplication {
    if (!MsalClient) {
        throw new Error("MsalClient not initialized");
    }
    return MsalClient;
}

export async function InitializeMsal(): Promise<PublicClientApplication>
{
    try {
        //Setup MSAL
        const conf = await GetConfiguration();
        MsalClient = new PublicClientApplication(conf);

        await MsalClient.initialize();
        
        //Check if there is a token in the cache
        if (MsalClient.getActiveAccount()) {
            return MsalClient;
        }
        
        let result = await MsalClient.handleRedirectPromise();

        // If user is not logged in, redirect to login page
        if(!result?.account) {
            //This causes a redirect, hence never returns
            await MsalClient.loginRedirect();
        }
        else
        {
            MsalClient.setActiveAccount(result.account);
        }

        return MsalClient;
    }
    catch (e) {
        console.error(`Failed to initialize MSAL\n${e}`);
        throw e;
    }
}